html, body, #root {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
  font-family: 'Didact Gothic', sans-serif;}

  input::selection{ color:black }

  li:hover {
    text-decoration: underline;
    cursor: pointer; 
  }

